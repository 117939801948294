import { useContext, Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "../routes";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Main from "../containers/Main";
import ThemedSuspense from "../components/ThemedSuspense";
import { SidebarContext } from "../context/SidebarContext";
import ErrorModal from "../components/Modals/ErrorModal";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext";
import { roleName } from "../constants_map";
import { handleHelpClick } from "./helpers";

const Page404 = lazy(() => import("../pages/404"));

function Layout() {
  const { isSidebarOpen } = useContext(SidebarContext);
  const {
    showSessionExpiredModal,
    setShowSessionExpiredModal,
    logout,
    userRole,
    collapsed,
  } = useContext(AuthContext);
  const { theme } = useTheme();
  const location = useLocation();
  const isStudioModule = location.pathname.includes("/app/studio/");
  const errorModal = () => {
    logout();
    setShowSessionExpiredModal(false);
  };
  const loggedInRole =
    userRole === roleName.enterpriseAdmin
      ? "EA"
      : userRole === roleName.enterpriseUser
      ? "EU"
      : userRole === roleName.platformAdmin
      ? "PA"
      : "NA";

  const handleHelp = () => {
    handleHelpClick(location.pathname, loggedInRole);
  };

  const shouldShowHelpButton =
    location.pathname !== "/app/home" &&
    location.pathname !== "/app/billing-pricing/invoicing" &&
    location.pathname !== "/app/policy/dnd/msisdn/upload" &&
    location.pathname !== "/app/policy/QuietTime/_add" &&
    location.pathname !== "/app/Routing/SMS/_create";

  return (
    <div
      style={{
        fontFamily: theme.font,
      }}
    >
      <Header />
      <div
        className={`flex flex-row h-min lg:relative  ${
          isStudioModule ? "md:px-5" : "md:p-5"
        }  ${isSidebarOpen && "overflow-hidden"}`}
      >
        <div className="lg:fixed">
          <Sidebar />
        </div>

        <div
          className={`flex  flex-col flex-1 w-full ${
            collapsed
              ? "lg:ltr:ml-[70px] lg:rtl:mr-[70px]"
              : "lg:ltr:ml-[201px] lg:rtl:mr-[201px]"
          }`}
        >
          <Main>
            <Suspense fallback={<ThemedSuspense />}>
              <Routes>
                {routes.map((route, i) => {
                  return route.component ? (
                    <Route
                      key={i}
                      path={`${route.path}`}
                      element={<route.component />}
                    />
                  ) : null;
                })}
                <Route path="/app" element={<Navigate to="/app/home" />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Suspense>
          </Main>
        </div>
        {shouldShowHelpButton && (
          <div
            className="inline relative flex"
            style={{
              position: "fixed",
              right: "-12px",
              top: "53%",
            }}
          >
            <button
              onClick={handleHelp}
              className="font-bold cursor-pointer inline"
              style={{
                fontSize: "12px",
                cursor: "pointer",
                color: "white",
                backgroundColor: theme.primaryColor,
                display: "block",
                padding: "2px 7px",
                transform: "rotate(90deg)",
                borderRadius: "5px",
              }}
            >
              <span>H</span>
              <span>E</span>
              <span>L</span>
              <span>P</span>
            </button>
          </div>
        )}
      </div>
      <ErrorModal
        errorModal={showSessionExpiredModal}
        deleteError={t("_Session_expired._Please_login_again.")}
        setErrorModal={errorModal}
        buttonTxt={"Log In"}
      />
    </div>
  );
}

export default Layout;
